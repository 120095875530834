import { useState, useEffect } from "react";
import Footer from "../components/footer";
import Header from "../components/header";
import ConnectMetamask from "../components/claim/connectMetamask";
import PublicSaleInvestor from "../components/claim/publicSaleInvestor";
import CoinAllocation from "../components/claim/coinAllocation";
import ClaimNotice from "../components/claim/claimNotice";
import LinkL1xWallet from "../components/claim/linkL1xWallet";
import YourL1xCoins from "../components/claim/yourL1xCoins";
import DisputeTicket from "../components/claim/disputeTicket";
import {
  serviceCheckClaimStatus,
  serviceCheckPublicSaleClaimStatus,
  serviceCheckTermSheetClaimStatus,
  serviceCheckPublicSaleEmpClaimStatus,
  serviceClaimToken,
  servicePublicSaleClaimEmail,
  servicePublicSaleClaimOTPVerfication,
  servicePublicSaleClaimToken,
  serviceTermSheetClaimToken,
  servicePublicSaleEmpClaimToken,
  serviceCheckSeedInvestorClaimStatus,
  serviceSeedInvestorClaimToken
} from "../services/claimService";
import Notify from "../components/notification";
import DisputeThankYouMessage from "../components/claim/disputeThankYouMessage";
import { ToastContainer, toast } from "react-toastify";

import * as buffer from "buffer";
import { ethers } from "ethers";

import Storage from "../services/storageService";
import _Config from "../assets/config";
import PublicSaleCoinAllocation from "../components/claim/publicSale/publicSaleCoinAllocation";
import validator from "validator";
import PublicSaleClaimNotice from "../components/claim/publicSale/publicSaleClaimNotice";
import PublicSaleYourL1xCoins from "../components/claim/publicSale/publicYourL1xCoins";
import PublicSaleEmpYourL1xCoins from "../components/claim/publicSaleEmpInvestments/publicSaleEmpYourL1xCoins";

import TermSheetL1xCoins from "../components/claim/publicSale/termSheetL1xCoins";
import TermSheetCoinAlloacation from "../components/claim/publicSale/termSheetCoinAlloacation";
import TermSheetClaimNotice from "../components/claim/publicSale/termSheetClaimNotice";
import PublicSaleEmpClaimNotice from "../components/claim/publicSaleEmpInvestments/publicSaleEmpClaimNotice";
import PublicSaleEmpCoinAllocation from "../components/claim/publicSaleEmpInvestments/publicSaleEmpCoinAllocation";
import PublicSaleEmpDisputeTicket from "../components/claim/publicSaleEmpInvestments/publicSaleEmpDisputeTicket";
import PublicSaleEmpDisputeThankYouMessage from "../components/claim/publicSaleEmpInvestments/publicSaleEmpDisputeThankYouMessage";
import SeedInvestorL1xCoins from "../components/claim/seedInvestor/seedInvestorL1xCoins";
import SeedInvestorCoinAlloaction from "../components/claim/seedInvestor/seedInvestorCoinAllocation";
import SeedInvestorClaimNotice from "../components/claim/seedInvestor/seedInvestorClaimNotice";

window.Buffer = buffer.Buffer;

const Claim = () => {
  const [currentPage, setCurrentPage] = useState("connectMetamask");
  const [coinAllocationData, setCoinAllocationData] = useState("");
  const [publicSaleAllocation, setPublicSaleAllocation] = useState(null);
  const [seedInvestorAllocation, setSeedInvestorAllocation] = useState(null);
  const [walletAddress, setWalletAddress] = useState("");
  const [l1xWalletAddress, setL1XWalletAddress] = useState("");
  const [walletNotFound, setWalletNotFound] = useState("");
  const [loader, setLoader] = useState(false);
  const [saleType, setSaleType] = useState("");

  const [email, setEmail] = useState("");
  const [otp, setOTP] = useState("");
  const [showOTP, setShowOTP] = useState(false);

  const [termSheetEmail, setTermsheetEmail] = useState("");
  const [termSheetOtp, setTermSheetOTP] = useState("");
  const [showTermshetOTP, setShowTermsheetOTP] = useState(false);

  const [seedInvestorEmail, setSeedInvestorEmail] = useState("");
  const [seedInvestorOtp, setSeedInvestorOTP] = useState("");
  const [showSeedInvestorOTP, setShowSeedInvestorOTP] = useState(false);

  let savedSaleType = localStorage.getItem("selectedTab");

  console.log("---------------------------", savedSaleType);

  const handleCurrentPage = (_pageName) => {
    setCurrentPage(_pageName);
  };


  async function getActiveMetamaskAddress() {
    try {
      // Get the first address
      let _arrAddress = await window.ethereum.request({ method: "eth_requestAccounts" });
      return _arrAddress[0];
    }
    catch (e) {
      console.error("getActiveMetamaskAddress Error", e);
      return false;
    }
  }

  async function signMessageFromWallet(_signingAddress, _actualSigningMessage) {
    try {

      const _msgHex = `0x${Buffer.from(_actualSigningMessage, 'utf8').toString('hex')}`;
      const _signedMessage = await window.ethereum.request({
        method: 'personal_sign',
        params: [_msgHex, _signingAddress],
      });
      console.log("🚀 ~ file: claim.js:87 ~ signMessageFromWallet ~ _signedMessage:", _signedMessage)

      return _signedMessage == false ? false : _signedMessage;

    } catch (err) {
      console.error("signMessageFromWallet Error: ", err);
      return false;
    }
  }

  async function extractAddressFromSignature(_actualSigningMessage, _signedMessage) {

    try {
      return ethers.verifyMessage(_actualSigningMessage, _signedMessage);
    }
    catch (err) {
      console.log("verifySignedMessage Error", err);
      return false;
    }

  }

  async function handleActiveMetaMaskAddress() {


    // Check if Signed Message Exists in Localstorage
    // If Yes
    // Try Extracting Public Key From it

    // If No
    // Try Signing Secret Message and Store Signature in Localstorage

    let _rawActiveAddress = await getActiveMetamaskAddress();
    if (_rawActiveAddress == false) {
      return Notify("Unable to get wallet details", "error");
    }



    let _signedMessage = Storage.getData(_Config.SIGNED_MESSAGE_KEY);
    if (_signedMessage == false) {


      // Sign Message Seed 
      let _constructedSignedMessage = await signMessageFromWallet(_rawActiveAddress, _Config.AUTHORIZED_MESSAGE_SEED);
      if (_constructedSignedMessage == false) {
        return Notify("Unable to sign the message", "error");
      }

      await Storage.setData(_Config.SIGNED_MESSAGE_KEY, {
        "hex": _constructedSignedMessage
      });

      _signedMessage = {};
      _signedMessage['hex'] = _constructedSignedMessage;

    }

    // Extract Address
    let _authorizedActiveAddress = await extractAddressFromSignature(_Config.AUTHORIZED_MESSAGE_SEED, _signedMessage.hex);
    _authorizedActiveAddress = _authorizedActiveAddress.toLowerCase();

    if (_authorizedActiveAddress != _rawActiveAddress) {

      // Reset Local Signature
      Storage.removeData(_Config.SIGNED_MESSAGE_KEY);
      // Go Back and Sign the Message
      return await handleActiveMetaMaskAddress();
    }

    return _authorizedActiveAddress;
  }

  //Check Private Sale Claim Status
  async function checkClaimStatus() {

    setLoader(true);
    try {

      if (!window.ethereum) {
        return Notify("MetaMask extension not found", "error");
      }

      setWalletAddress(null);

      // Handle Active Wallet Address
      let _authorizedWalletAddress = await handleActiveMetaMaskAddress();

      setWalletAddress(_authorizedWalletAddress);


      try {
        let _response = await serviceCheckClaimStatus(_authorizedWalletAddress);
        if (_response.status == "success") {
          setCoinAllocationData(_response.data);
          setLoader(false);

          if (!_response.data.is_processed && !_response.data.publicSale && !_response.data.privateSale) {

            return setWalletNotFound("Wallet Address not found");
          }

          if (_response.data.is_processed) {
            return handleCurrentPage("yourL1xCoins");
          }

          if (_response.data.publicSale && _response.data.privateSale) {
            return handleCurrentPage("coinAllocation");
          }

          if (_response.data.privateSale) {
            return handleCurrentPage("coinAllocation");
          }

          if (_response.data.publicSale) {
            return handleCurrentPage("publicSaleInvestor");
          }
        }

      }
      catch (err) {
        return Notify("Unable to get Claim details at the moment, Please try again later", "error");
      }

    } catch (err) {
      setLoader(false);
      console.log("Error Occured, Please try again later ", err);
    }

    setLoader(false);
  }

  function removePrefixOx(inputString) {
    if (inputString?.startsWith("0x")) {
      return inputString?.substring(2);
    }
    return inputString;
  }

  // Common Claim Token for all sale type 
  async function claimToken(_l1xWalletAddress) {
    try {
      console.log("--- type", savedSaleType);

      if (validator.isEmpty(_l1xWalletAddress)) {
        return Notify("Please enter L1x wallet address", 'error')
      }


      let _l1xWalletAddressWithoutOx = removePrefixOx(_l1xWalletAddress)

      if (savedSaleType == "private") {
        if (validator.isEmpty(walletAddress)) {
          return Notify("Please enter wallet address", 'error')
        }

        let data = {
          walletAddress: walletAddress,
          l1xWalletAddress: _l1xWalletAddressWithoutOx,
        };
        setLoader(true)

        let _response = await serviceClaimToken(data);
        if (_response.status == "success") {
          console.log("_response", _response)
          await checkClaimStatus();
          setLoader(false)
          // return Notify("Claim succesfull", "success");
        } else {
          setLoader(false)

          return Notify("Something went wrong, please try again", "error");
        }

      }
      else if (savedSaleType == "public") {
        if (validator.isEmpty(email)) {
          return Notify("Email address not found", 'error')
        }
        if (!validator.isEmail(email)) {
          return Notify("Valid Email address not found", 'error')
        }

        let data = {
          email: email,
          l1xWalletAddress: _l1xWalletAddressWithoutOx,
        };
        setLoader(true)

        let _response = await servicePublicSaleClaimToken(data);

        let _responseStatus = await serviceCheckPublicSaleClaimStatus(email);

        if (_responseStatus.status == "success") {
          setPublicSaleAllocation(_responseStatus.data)
        }

        if (_response.status == "success") {
          setCurrentPage('publicSaleYourL1xCoins')
          setLoader(false)
          // return Notify("Claim succesfull", "success");
        } else {
          setLoader(false)

          return Notify("Something went wrong, please try again", "error");
        }
      }
      else if (savedSaleType == "termSheet") {
        if (validator.isEmpty(termSheetEmail)) {
          return Notify("Email address not found", 'error')
        }
        if (!validator.isEmail(termSheetEmail)) {
          return Notify("Valid Email address not found", 'error')
        }

        let data = {
          email: termSheetEmail,
          l1xWalletAddress: _l1xWalletAddressWithoutOx,
        };
        setLoader(true)

        let _response = await serviceTermSheetClaimToken(data);
        if (_response.status == "success") {
          setCurrentPage('termSheetL1xCoins')
          setLoader(false)
        } else {
          setLoader(false)

          return Notify("Something went wrong, please try again", "error");
        }
      }
      else if (savedSaleType == "publicSaleEmpInvestment") {
        if (validator.isEmpty(walletAddress)) {
          return Notify("Please enter wallet address", 'error')
        }

        let data = {
          walletAddress: walletAddress,
          l1xWalletAddress: _l1xWalletAddressWithoutOx,
        };
        setLoader(true)

        let _response = await servicePublicSaleEmpClaimToken(data);

        if (_response.status == "success") {
          console.log("_response", _response)
          setCurrentPage('publicSaleEmpYourL1xCoins')

          setLoader(false)
          // return Notify("Claim succesfull", "success");
          toast.success(_response.message)

        } else {
          setLoader(false)
          toast.error("Something went wrong, please try again", "error");
          return Notify("Something went wrong, please try again", "error");
        }
      } else if (savedSaleType == "seedInvestor") {
        if (validator.isEmpty(seedInvestorEmail)) {
          return Notify("Email address not found", 'error')
        }
        if (!validator.isEmail(seedInvestorEmail)) {
          return Notify("Valid Email address not found", 'error')
        }

        let data = {
          email: seedInvestorEmail,
          l1xWalletAddress: _l1xWalletAddressWithoutOx,
        };
        setLoader(true)

        let _response = await serviceSeedInvestorClaimToken(data);
        if (_response.status == "success") {
          Notify(_response.message, 'success');
          setCurrentPage('seedInvestorL1xCoins')
          setLoader(false)
        } else {
          setLoader(false)
          return Notify(_response.message, 'error');
        }
      }

    } catch (err) {
      setLoader(false)

      return Notify("Something went wrong, please try again", "error");
    }
  }

  // Handle Coin Allocation Data
  const handleCoinAllocationData = (_coinAllocationData) => {
   
    coinAllocationData = _coinAllocationData;
  };

  // Handle Wallet Address Data
  const handleWalletAddressData = (_walletAddress) => {
   
    walletAddress = _walletAddress;
  };

  // Check Public sale claim status
  const checkPublicSaleClaimStatus = async () => {
    try {

      if (validator.isEmpty(email)) {
        Notify("Please enter email address", 'error');
        return
      }
      else if (!validator.isEmail(email)) {
        Notify("Please enter valid email address", "error");
        return
      }

      if (!showOTP) {

        let _emailResponse = await servicePublicSaleClaimEmail({ email: email, type: 2 })

        setSaleType("public")
        if (_emailResponse.status === 'success') {
          Notify("OTP sent", "success")
          setShowOTP(true)

          return
        }
        else {
          Notify("Error while sending email", "error")
          setShowOTP(false)
          return
        }
      }

      let _OTPResponse = await servicePublicSaleClaimOTPVerfication({ email: email, otp: otp, type: 2 })

      if (_OTPResponse.status === 'success') {
        Notify("OTP verified", "success")
        setOTP("");
        setShowOTP(false)
        // setShowOTP(true)
      }
      else {
        Notify("Unable to verify", "error")
        setOTP("");
        // setShowOTP(false)
        return
      }

      let _response = await serviceCheckPublicSaleClaimStatus(email);
    
      if (_response.status == "success") {

        setPublicSaleAllocation(_response.data)
        setLoader(false)
        if (_response.data.is_processed) {

          setCurrentPage('publicSaleYourL1xCoins')
          return
        }

        setCurrentPage('publicSaleCoinAllocation')
        // return Notify("Claim succesfull", "success");
      } else {
        setLoader(false)

        return Notify(_response.message, "error");
      }
    } catch (err) {
      setLoader(false)

      return Notify("Something went wrong, please try again", "error");
    }
  }

  // Check Term Sheet Claim Status
  const checkTermSheetClaimStatus = async () => {
    try {

      if (validator.isEmpty(termSheetEmail)) {
        Notify("Please enter email address", 'error');
        return
      }
      else if (!validator.isEmail(termSheetEmail)) {
        Notify("Please enter valid email address", "error");
        return
      }

      if (!showTermshetOTP) {

        let _emailResponse = await servicePublicSaleClaimEmail({ email: termSheetEmail, type: 3 })
        setSaleType("termSheet")
        if (_emailResponse.status === 'success') {
          Notify("OTP sent", "success")
          setShowTermsheetOTP(true)
          return
        }
        else {
          Notify("Error while sending email", "error")
          setShowTermsheetOTP(false)
          return
        }
      }

      let _OTPResponse = await servicePublicSaleClaimOTPVerfication({ email: termSheetEmail, otp: termSheetOtp, type: 3 })

      if (_OTPResponse.status === 'success') {
        Notify("OTP verified", "success")
        setTermSheetOTP("");
        setShowTermsheetOTP(false)
      }
      else {
        Notify("Unable to verify", "error")
        setTermSheetOTP("");
        return
      }

      let _response = await serviceCheckTermSheetClaimStatus(termSheetEmail);
      if (_response.status == "success") {

        setPublicSaleAllocation(_response.data)
        setLoader(false)
        if (_response.data.is_processed) {
          setCurrentPage('termSheetL1xCoins')
          return
        }

        setCurrentPage('termSheetCoinAllocation')
        // return Notify("Claim succesfull", "success");
      } else {
        setLoader(false)

        return Notify(_response.message, "error");
      }
    } catch (err) {
      setLoader(false)

      return Notify("Something went wrong, please try again", "error");
    }
  }

  //================================================================= Check Seed Investor Claim Status
  const checkSeedInvestorClaimStatus = async () => {
    try {

      if (validator.isEmpty(seedInvestorEmail)) {
        Notify("Please enter email address", 'error');
        return
      }
      else if (!validator.isEmail(seedInvestorEmail)) {
        Notify("Please enter valid email address", "error");
        return
      }

      if (!showSeedInvestorOTP) {

        let _emailResponse = await servicePublicSaleClaimEmail({ email: seedInvestorEmail, type: 5 })
        setSaleType("seedInvestor")
        if (_emailResponse.status === 'success') {
          Notify("OTP sent", "success")
          setShowSeedInvestorOTP(true)
          return
        }
        else {
          Notify("Error while sending email", "error")
          setShowSeedInvestorOTP(false)
          return
        }
      }

      let _OTPResponse = await servicePublicSaleClaimOTPVerfication({ email: seedInvestorEmail, otp: seedInvestorOtp, type: 5 })

      if (_OTPResponse.status === 'success') {
        Notify("OTP verified", "success")
        setSeedInvestorOTP("");
        setShowSeedInvestorOTP(false)
      }
      else {
        Notify("Unable to verify", "error")
        setSeedInvestorOTP("");
        return
      }

      let _response = await serviceCheckSeedInvestorClaimStatus(seedInvestorEmail);

      if (_response.status == "success") {
        setSeedInvestorAllocation(_response.data)
        setLoader(false)
        if (_response.data.is_processed) {
          setCurrentPage('seedInvestorL1xCoins')
          return
        }

        setCurrentPage('seedInvestorCoinAllocation')
      } else {
        setLoader(false)
        return Notify(_response.message, "error");
      }
    } catch (err) {
      setLoader(false)
      return Notify("Something went wrong, please try again", "error");
    }
  }

  //Check Public Sale EMP Investment Claim Status
  async function checkPublicSaleEmpInvestmentClaimStatus() {

    setLoader(true);
    try {

      if (!window.ethereum) {
        return Notify("MetaMask extension not found", "error");
      }

      setWalletAddress(null);

      // Handle Active Wallet Address
      let _authorizedWalletAddress = await handleActiveMetaMaskAddress();

      setWalletAddress(_authorizedWalletAddress);


      try {
        let _response = await serviceCheckPublicSaleEmpClaimStatus(_authorizedWalletAddress);
        console.log("response", _response)
        if (_response.status == "success") {
          setCoinAllocationData(_response.data);
          setLoader(false);

          if (!_response.data.is_processed && !_response.data.empSale) {

            return setWalletNotFound("Wallet Address not found");
          }

          if (_response.data.is_processed) {
            return handleCurrentPage("publicSaleEmpYourL1xCoins");
          }

          if (_response.data.empSale) {
            // toast.success(_response.message);
            return handleCurrentPage("publicSaleEmpCoinAllocation");

          }

          if (_response.data.empSale) {
            return handleCurrentPage("publicSaleInvestor");
          }
        }

      }
      catch (err) {
        toast.error(err.message);
        return Notify("Unable to get Claim details at the moment, Please try again later", "error");
      }

    } catch (err) {
      setLoader(false);
      toast.error(err.message);
      console.log("Error Occured, Please try again later ", err);
    }

    setLoader(false);
  }

  return (
    <div>
      <Header />
      <div className="claim-middle-container">
        <div className="main_body_container" style={{ height: `calc(100vh - 280px)` }}>
          {currentPage === "connectMetamask" ? (
            <ConnectMetamask
              checkClaim={() => checkClaimStatus()}
              checkPublicSaleClaim={() => checkPublicSaleClaimStatus()}
              handleCoinAllocation={(_coinAllocationData) =>
                handleCoinAllocationData(_coinAllocationData)
              }
              handleWalletAddress={(_walletAddress) =>
                handleWalletAddressData(_walletAddress)
              }

              handleEmail={(_email) => setEmail(_email)}
              handleOTP={(_otp) => setOTP(_otp)}
              email={email}
              otp={otp}
              showOTP={showOTP}
              checkTermSheetClaim={() => checkTermSheetClaimStatus()}
              checkPublicSaleEmpClaim={() => checkPublicSaleEmpInvestmentClaimStatus()}
              handleTermSheetEmail={(_email) => setTermsheetEmail(_email)}
              handleTermSheetOTP={(_otp) => setTermSheetOTP(_otp)}
              termSheetEmail={termSheetEmail}
              termSheetOtp={termSheetOtp}
              showTermshetOTP={showTermshetOTP}
              loading={loader}
              walletNotFound={walletNotFound}
              handleSeedInvestorEmail={(_email) => setSeedInvestorEmail(_email)}
              handleSeedInvestorOTP={(_otp) => setSeedInvestorOTP(_otp)}
              seedInvestorEmail={seedInvestorEmail}
              seedInvestorOTP={seedInvestorOtp}
              showSeedInvestorOTP={showSeedInvestorOTP}
              checkSeedInvestorClaim={() => checkSeedInvestorClaimStatus()}
            />
          ) : (
            ""
          )}
          {currentPage === "publicSaleInvestor" ? <PublicSaleInvestor /> : ""}
          {currentPage === "coinAllocation" ? (
            <CoinAllocation
              allocationData={coinAllocationData}
              walletAddress={walletAddress}
              handlePage={(_pageName) => handleCurrentPage(_pageName)}
            />
          ) : (
            ""
          )}
          {currentPage === "claimNotice" ? (
            <ClaimNotice
              allocationData={coinAllocationData}
              handlePage={(_pageName) => handleCurrentPage(_pageName)}
            />
          ) : (
            ""
          )}
          {currentPage === "linkL1xWallet" ? (
            <LinkL1xWallet
              handlePage={(_pageName) => handleCurrentPage(_pageName)}
              claimToken={(_l1xWalletAddress) => claimToken(_l1xWalletAddress)}
              loader={loader}
            />
          ) : (
            ""
          )}
          {currentPage === "yourL1xCoins" ? (
            <YourL1xCoins
              handlePage={(_pageName) => handleCurrentPage(_pageName)}
              allocationData={coinAllocationData}
              walletAddress={walletAddress}

            />
          ) : (
            ""
          )}
          {currentPage === "disputeTicket" ? (
            <DisputeTicket
              handlePage={(_pageName) => handleCurrentPage(_pageName)}
              saleType={saleType}
            />
          ) : (
            ""
          )}
          {currentPage === "disputeThankYouMessage" ? (
            <DisputeThankYouMessage
              handlePage={(_pageName) => handleCurrentPage(_pageName)}
            />
          ) : (
            ""
          )}
          {/* Public Sale Claim Flow */}

          {currentPage === "publicSaleCoinAllocation" ? (
            <PublicSaleCoinAllocation
              allocationData={publicSaleAllocation}
              walletAddress={''}
              handlePage={(_pageName) => handleCurrentPage(_pageName)}
              handleType={(_type) => setSaleType(_type)}
            />
          ) : (
            ""
          )}
          {currentPage === "publicSaleClaimNotice" ? (
            <PublicSaleClaimNotice
              allocationData={publicSaleAllocation}
              handlePage={(_pageName) => handleCurrentPage(_pageName)}
              handleType={(_type) => setSaleType(_type)}
            />
          ) : (
            ""
          )}

          {currentPage === "publicSaleYourL1xCoins" ? (
            <PublicSaleYourL1xCoins
              handlePage={(_pageName) => handleCurrentPage(_pageName)}
              allocationData={publicSaleAllocation}
              walletAddress={walletAddress}
            />
          ) : (
            ""
          )}

          {currentPage === "termSheetL1xCoins" ? (
            <TermSheetL1xCoins
              handlePage={(_pageName) => handleCurrentPage(_pageName)}
              allocationData={publicSaleAllocation}
              walletAddress={walletAddress}
            />
          ) : (
            ""
          )}
          {currentPage === "termSheetCoinAllocation" ? (
            <TermSheetCoinAlloacation
              allocationData={publicSaleAllocation}
              walletAddress={''}
              handlePage={(_pageName) => handleCurrentPage(_pageName)}
              handleType={(_type) => setSaleType(_type)}
            />
          ) : (
            ""
          )}
          {currentPage === "termSheetClaimNotice" ? (
            <TermSheetClaimNotice
              allocationData={publicSaleAllocation}
              walletAddress={''}
              handlePage={(_pageName) => handleCurrentPage(_pageName)}
              handleType={(_type) => setSaleType(_type)}
            />
          ) : (
            ""
          )}


          {/* Public Sale Emp Investments */}

          {currentPage === "publicSaleEmpCoinAllocation" ? (
            <PublicSaleEmpCoinAllocation
              allocationData={coinAllocationData}
              walletAddress={walletAddress}
              handlePage={(_pageName) => handleCurrentPage(_pageName)}
            />
          ) : (
            ""
          )}
          {currentPage === "publicSaleEmpClaimNotice" ? (
            <PublicSaleEmpClaimNotice
              allocationData={coinAllocationData}
              handlePage={(_pageName) => handleCurrentPage(_pageName)

              }
            />
          ) : (
            ""
          )}


          {currentPage === "publicSaleEmpYourL1xCoins" ? (
            <PublicSaleEmpYourL1xCoins
              handlePage={(_pageName) => handleCurrentPage(_pageName)}
              allocationData={coinAllocationData}
              walletAddress={walletAddress}
            />
          ) : (
            ""
          )}

          {currentPage === "publicSaleEmpDisputeTicket" ? (
            <PublicSaleEmpDisputeTicket
              handlePage={(_pageName) => handleCurrentPage(_pageName)}
              saleType={saleType}
            />
          ) : (
            ""
          )}


          {currentPage === "publicSaleEmpDisputeThankYouMessage" ? (
            <PublicSaleEmpDisputeThankYouMessage
              handlePage={(_pageName) => handleCurrentPage(_pageName)}
            />
          ) : (
            ""
          )}

          {/* //=================================== SEED INVESTORS ============================= */}
          {currentPage === "seedInvestorL1xCoins" ? (
            <SeedInvestorL1xCoins
              handlePage={(_pageName) => handleCurrentPage(_pageName)}
              allocationData={seedInvestorAllocation}
              walletAddress={""}
            />
          ) : (
            ""
          )}
          {currentPage === "seedInvestorCoinAllocation" ? (
            <SeedInvestorCoinAlloaction
              allocationData={seedInvestorAllocation}
              walletAddress={''}
              handlePage={(_pageName) => handleCurrentPage(_pageName)}
              handleType={(_type) => setSaleType(_type)}
            />
          ) : (
            ""
          )}
          {currentPage === "seedInvestorClaimNotice" ? (
            <SeedInvestorClaimNotice
              allocationData={seedInvestorAllocation}
              walletAddress={''}
              handlePage={(_pageName) => handleCurrentPage(_pageName)}
              handleType={(_type) => setSaleType(_type)}
            />
          ) : (
            ""
          )}


        </div>
      </div>
      <ToastContainer />
      <Footer />
    </div>
  );
};

export default Claim;
