import { getData, postRawJsonData, getOpsData1 } from "../Api/index";

async function serviceGetWalletAddress() {
  let _response = await getOpsData1(
    `/api/public_sales/view_early_access_status`
  );
  return _response;
}

export { serviceGetWalletAddress };
