// import logo from './logo.svg';
import { Route, Routes } from "react-router-dom";
// import Home from "./pages/home";
// import NewWallet from "./pages/newWallet";
// import SwapPage from "./pages/swapPage";
// import Staking from "./pages/staking";
// import StakingPlatform from "./pages/stakingPlatform";
// import Staking from './pages/staking';
// import Wallet from './pages/wallet';
// import SwapPage1 from './pages/swapPage1';
// import SwapPage2 from './pages/swapPage2';
// import SwapPage3 from './pages/swapPage3';
// import NewWallet from './pages/newWallet';
import Claim from "./pages/claim";
import ViewEarlyAccessList from "./pages/viewEarlyAccessList";
// import Tokens from "./pages/tokens";

import "./App.css";
import "./assets/styles/style.css";

function App() {
  return (
    <Routes>
      {/* <Route
        exact
        path="/Home"
        element={
          <Home
          ></Home>
        }
      /> */}

      {/* <Route exact path="/newWallet" element={<NewWallet />} /> */}

      <Route exact path="" element={<Claim></Claim>} />
      {/*   <Route exact path="/swap" element={<SwapPage></SwapPage>} />*/}

      {/* <Route exact path="/staking" element={<Staking></Staking>} /> */}

      {/* <Route
        exact
        path="/staking-platform"
        element={<StakingPlatform></StakingPlatform>}
      /> */}
      {/* <Route exact path="/swapPage" element={<SwapPage1 />} /> */}

      {/* <Route exact path="/tokens" element={<Tokens />} /> */}

      <Route exact path="/claim" element={<Claim />} />

      <Route exact path="/accesslist" element={<ViewEarlyAccessList />} />
    </Routes>
  );
}

export default App;
